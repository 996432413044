// STRING CONSTANTS
export enum AccessTypeTitleEnum {
  combinationLockbox = 'Combination lockbox',
  appLockbox = 'App-enabled lockbox',
  meetContactOnsite = 'Meet contact on-site',
  hiddenKey = 'Hidden key',
  communityAccess = 'Community access',
  doorCode = 'Door code',
  showingTime = 'ShowingTime',
  general = 'General access information (legacy)'
}

export enum AccessTypeSubtitleEnum {
  appLockbox = 'Examples include: Supra, Rently, SentriLock',
  meetContactOnsite = 'The contact will be on-site at the time of the inspection and provide access.',
  communityAccess = 'Gate or building access, must be paired with another access type for the property',
  showingTime = 'Inspectify will book the Inspection through ShowingTime.',
}

export enum InputFieldEnum {
  code = 'Code',
  directions = 'Directions',
  brand = 'Brand',
  fullName = 'Full name',
  phoneNumber = 'Phone number',
  extension = 'Ext.',
  mlsOuid = 'MLS OUID',
  mlsId = 'MLS ID',
  email = 'Email'
}

export enum InputFieldErrorEnum {
  code = 'Add the lock code',
  directionsLockbox = 'Describe the lockbox location on the property',
  directionsHiddenKey = 'Specify where the key is hidden on the property',
  directionsCommunity = 'Describe how to access the community',
  directionsDoor = 'Describe which door the code accesses', 
  brand = 'Choose the brand',
  fullName = 'Enter the contact\'s full name',
  phoneNumber = 'Enter a valid phone number',
  mlsOuid = 'Enter a valid MLS name',
  email = 'Enter the contact\'s email'
}

export enum InputFieldPlaceholderEnum {
  code = '0000',
  directions = 'ex. Attached to the fence on the west side of the house.',
  brand = 'Select brand',
  fullName = 'John Doe',
  phoneNumber = '(000) 000-0000',
  extension = '000',
  mlsOuid = 'Select MLS Name',
  mlsId = 'ex. 000000',
  email = 'johndoe@example.com'
}
 
export enum AppLockboxBrandEnum {
  supraEkey = 'Supra/Ekey',
  rently = 'Rently',
  sentriLock = 'SentriLock',
  other = 'Other',
}

// TYPES
export type InputField = {
  title: InputFieldEnum,
  placeholder: InputFieldPlaceholderEnum,
}

export interface AccessInfoFormLoaderProps {
  mlsOuids:  { [id: string]: string },
  accessInformations?: any[],
  requireOnSiteContactEmail: boolean,
}

export interface AccessInfoFormProps {
  minimized: boolean,
  mlsOuids:  { [id: string]: string },
  state: AccessInfoFormState,
  setState: (key: AccessTypeEnum, value: any) => void,
  handleShowErrorMessage: (accessType: AccessType) => void,
  handleSelectAccessType: (accessType: AccessType) => void,
}

export type AccessInfoType = {
  type: AccessTypeEnum,
  title: AccessTypeTitleEnum,
  subtitle: AccessTypeSubtitleEnum | undefined,
  isSelected: boolean,
  inputFields: InputFieldEnum[],
}

export interface CombinationLockboxType extends AccessInfoType {
  code: string | undefined,
  directions: string | undefined,
  showCodeError: boolean | undefined,
  showDirectionsError: boolean | undefined,
}

export interface AppEnabledLockboxType extends AccessInfoType {
  brand: string | undefined,
  directions: string | undefined,
  showBrandError: boolean | undefined,
  showDirectionsError: boolean | undefined,
}

export interface MeetContactOnsiteType extends AccessInfoType {
  fullName: string | undefined,
  phoneNumber: string | undefined,
  extension: string | undefined,
  email: string | undefined,
  showFullNameError: boolean | undefined,
  showPhoneNumberError: boolean | undefined,
  showEmailError: boolean | undefined
}

export interface HiddenKeyType extends AccessInfoType {
  directions: string | undefined,
  showDirectionsError: boolean | undefined,
}

export interface CommunityAccessType extends AccessInfoType {
  code: string | undefined,
  directions: string | undefined,
  showCodeError: boolean | undefined,
  showDirectionsError: boolean | undefined,
}

export interface DoorCodeType extends AccessInfoType {
  code: string | undefined,
  directions: string | undefined,
  showCodeError: boolean | undefined,
  showDirectionsError: boolean | undefined,
}

export interface ShowingTimeType extends AccessInfoType {
  mlsOuid: string | undefined,
  mlsId: string | undefined,
  showMlsOuidError: boolean | undefined,
}

export type AccessType =
  | AccessInfoType
  | AppEnabledLockboxType
  | CombinationLockboxType
  | CommunityAccessType
  | DoorCodeType
  | HiddenKeyType
  | MeetContactOnsiteType
  | ShowingTimeType;

// STATE
export enum Action {
  Set = 'SET',
  SetMulti = 'SET_MULTI',
  Reset = 'RESET',
}

export enum AccessTypeEnum {
  combinationLockbox = 'combinationLockbox',
  appEnabledLockbox = 'appEnabledLockbox',
  meetContactOnsite = 'meetContactOnsite',
  hiddenKey = 'hiddenKey',
  communityAccess = 'communityAccess',
  doorCode = 'doorCode',
  showingTime = 'showingTime',
  general = 'general'
}

export enum AccessInformationDBTypes {
  combinationLockbox = 'AccessInformation::CombinationLockbox',
  appEnabledLockbox = 'AccessInformation::AppEnabledLockbox',
  meetContactOnsite = 'AccessInformation::MeetContactOnSite',
  hiddenKey = 'AccessInformation::HiddenKey',
  communityAccess = 'AccessInformation::CommunityAccess',
  doorCode = 'AccessInformation::DoorCode',
  showingTime = 'AccessInformation::ShowingTime',
  general = 'AccessInformation'
}

export type StateDispatchType = {
  type: Action,
  key?: AccessTypeEnum,
  value?: any,
}

export type AccessInfoFormState = {
  combinationLockbox: CombinationLockboxType,
  appEnabledLockbox: AppEnabledLockboxType,
  meetContactOnsite: MeetContactOnsiteType,
  hiddenKey: HiddenKeyType,
  communityAccess: CommunityAccessType,
  doorCode: DoorCodeType,
  showingTime: ShowingTimeType,
}

// INITIAL STATES
export const initialStateAppEnabledLockbox: AppEnabledLockboxType = {
  type: AccessTypeEnum.appEnabledLockbox,
  title: AccessTypeTitleEnum.appLockbox,
  subtitle: AccessTypeSubtitleEnum.appLockbox,
  isSelected: false,
  inputFields: [InputFieldEnum.brand, InputFieldEnum.directions],
  brand: '',
  directions: '',
  showBrandError: false,
  showDirectionsError: false,
}

export const initialStateCombinationLockbox: CombinationLockboxType = {
  type: AccessTypeEnum.combinationLockbox,
  title: AccessTypeTitleEnum.combinationLockbox,
  subtitle: undefined,
  isSelected: false,
  inputFields: [InputFieldEnum.code, InputFieldEnum.directions],
  code: '',
  directions: '',
  showCodeError: false,
  showDirectionsError: false,
}

export const initialStateMeetContactOnsite: MeetContactOnsiteType = {
  type: AccessTypeEnum.meetContactOnsite,
  title: AccessTypeTitleEnum.meetContactOnsite,
  subtitle: AccessTypeSubtitleEnum.meetContactOnsite,
  isSelected: false,
  inputFields: [InputFieldEnum.fullName, InputFieldEnum.phoneNumber, InputFieldEnum.extension, InputFieldEnum.email],
  fullName: '',
  phoneNumber: '',
  extension: '',
  email: '',
  showFullNameError: false,
  showPhoneNumberError: false,
  showEmailError: false
}

export const initialStateHiddenKey: HiddenKeyType = {
  type: AccessTypeEnum.hiddenKey,
  title: AccessTypeTitleEnum.hiddenKey,
  subtitle: undefined,
  isSelected: false,
  inputFields: [InputFieldEnum.directions],
  directions: '',
  showDirectionsError: false,
}

export const initialStateCommunityAccess: CommunityAccessType = {
  type: AccessTypeEnum.communityAccess,
  title: AccessTypeTitleEnum.communityAccess,
  subtitle: AccessTypeSubtitleEnum.communityAccess,
  isSelected: false,
  inputFields: [InputFieldEnum.code, InputFieldEnum.directions],
  code: '',
  directions: '',
  showCodeError: false,
  showDirectionsError: false,
}

export const initialStateDoorCode: DoorCodeType = {
  type: AccessTypeEnum.doorCode,
  title: AccessTypeTitleEnum.doorCode,
  subtitle: undefined,
  isSelected: false,
  inputFields: [InputFieldEnum.code, InputFieldEnum.directions],
  code: '',
  directions: '',
  showCodeError: false,
  showDirectionsError: false,
}

export const initialStateShowingTime: ShowingTimeType = {
  type: AccessTypeEnum.showingTime,
  title: AccessTypeTitleEnum.showingTime,
  subtitle: AccessTypeSubtitleEnum.showingTime,
  isSelected: false,
  inputFields: [InputFieldEnum.mlsOuid, InputFieldEnum.mlsId],
  mlsOuid: '',
  mlsId: '',
  showMlsOuidError: false,
}
 